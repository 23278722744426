import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "./ProjectCards";
// import BlogCard from "./BlogsCards";
import Particle from "../Particle";

import bazaarsa from "../../Assets/Projects/bazaarsa.png";
import taskList from "../../Assets/Projects/taskList.png";
import beauty from "../../Assets/Projects/beauty.png";
// import kids from "../../Assets/Projects/kids.png";
// import cook from "../../Assets/Projects/cook.png";
import cars from "../../Assets/Projects/cars.png";
import camping from "../../Assets/Projects/camping.png";

function Projects() {
  return (
    <Container fluid className="project-section">
      <Particle />
      <Container>
        <h1 className="project-heading">
          My Recent <strong className="purple">Works </strong>
        </h1>
        <p style={{ color: "white" }}>
          Here are a few projects I've worked on recently.
        </p>

        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={bazaarsa}
              //isBlog={false}
              title="ecommerce website"
              description="in this website we sell the parfum products in saudi arabia"
              link="https://www.bazaarsa.shop/"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={taskList}
              //isBlog={false}
              //title="Task list"
              description="this project is help you to how to organize your tasks "
              link="https://github.com/soufyanesraoui24"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={cars}
              title="Buy and sell cars"
              description="In this you can buy a car and you can set your ads for sell your car"
              //link="https://github.com/soufyanesraoui24"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={beauty}
              //isBlog={false}
              //title=""
              //description=""
              link="https://github.com/soufyanesraoui24"
              />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={camping}
              //isBlog={false}
              //title=""
              //description=""
              link="https://github.com/soufyanesraoui24"
              />
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default Projects;
